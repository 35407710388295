<template>
  <div>
    <div class="custom-search">
      <!-- advance search input -->
      <b-row v-if="detail">
        <b-col cols="2">
          <b-form-group>
            <label>Tipo de Inspección:</label>
            <v-select
              v-model="tipoInspeccion"
              label="title"
              :options="tiposInspecciones"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <label>Etapa:</label>
            <v-select
              v-model="etapaBusqueda"
              label="title"
              :options="etapas"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <label>Fecha Inicio:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="example-input"
                v-model="fechaInicio"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaInicio"
                  show-decade-nav
                  button-only
                  right
                  size="sm"
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group>
            <label>Fecha Fin:</label>
            <b-input-group class="mb-1">
              <b-form-input
                id="fecha-fin"
                v-model="fechaFin"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
                show-decade-nav
              />
              <b-input-group-append>
                <b-form-datepicker
                  v-model="fechaFin"
                  show-decade-nav
                  button-only
                  size="sm"
                  right
                  locale="en-US"
                  aria-controls="example-input"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          style="padding-top: 4px;"
          cols="1"
        >
          <br>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-tooltip.hover.v-primary
            title="Buscar"
            variant="primary"
            class="btn-icon"
            @click="loadReport()"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <b-col
          style="padding-top: 3px;"
          cols="1"
        >
          <br>
          <table>
            <tr>
              <td>
                <reporte-general
                  :rows="rowsReporte"
                  :columns="columnsReporte"
                  :titulo-principal="tituloPrincipal"
                  :titulo-secundario="tituloSecundario"
                  :subtitulo="subtitulo"
                  :titulo-descarga="tituloDescarga"
                />
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            style-class="vgt-table condensed"
            @on-row-dblclick="onRowClick"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span style="font-size: 13px">
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </div>
    <!---- MODALES ---->
    <b-modal
      v-model="dialog"
      centered
      ok-title="Aceptar"
      ok-only
      size="lg"
      title="Detalle de Inspección"
    >
      <InspeccionRutaDetalle :inspeccion="insepeccion" />
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCol, BRow, BFormDatepicker, BInputGroup, BFormGroup, BInputGroupAppend, BFormInput, BFormSelect, BPagination, VBPopover, VBModal, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { findInspecciones, findInspeccionId } from '@/utils/inspecciones'
import { formatDate, obtenerFechaInicioFin, sumarDiasFecha } from '@/utils/fechas'
import InspeccionRutaDetalle from '@/components/Inspecciones/InspeccionRutaDetalle.vue'
import {
  getColumns, getRows,
  subtitulo,
  tituloDescarga,
  tituloPrincipal,
  tituloSecundario,
} from '@/utils/reportes/inspecciones'
import ReporteGeneral from '@/components/reportes/reporteGeneral.vue'

export default {
  name: 'Reporte',
  components: {
    ReporteGeneral,
    InspeccionRutaDetalle,
    BButton,
    BCol,
    BRow,
    BFormDatepicker,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    vSelect,
    BFormInput,
    VueGoodTable,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    'b-modal': VBModal,
  },
  props: {
    detail: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    numeroPoste: {
      type: String,
    },
  },
  data() {
    return {
      tipoInspeccion: {
        title: 'Todas',
        value: '',
      },
      tiposInspecciones: [{ title: 'Todas', value: '' }, { title: 'Nocturas', value: 'NOCTURNA' }, { title: 'Diurna', value: 'DIURNA' }],
      etapaBusqueda: {
        title: 'Todas',
        value: '',
      },
      etapas: [
        { title: 'Todas', value: '' },
        { title: 'Pendiente', value: 'PENDIENTE' },
        { title: 'En Curso', value: 'EN CURSO' },
        { title: 'Finalizadas', value: 'FINALIZADA' },
      ],
      fechaInicio: '',
      fechaFin: '',
      rows: [],
      columns: [
        {
          label: 'Ruta',
          field: 'ruta.nombre',
        },
        {
          label: 'Tipo',
          field: 'tipo',
        },
        {
          label: 'Etapa',
          field: 'estado',
        },
        {
          label: 'Fecha Ingreso',
          field: 'fechaCreacion',
        },
        {
          label: 'Responsable',
          field: 'usuario.nombre',
        },
        {
          label: 'Fecha Atención',
          field: 'fechaAtencion',
        },
      ],
      searchTerm: '',
      perPage: 5,
      totalRows: 1,
      currentPage: 1,
      pageLength: 15,
      dialog: false,
      insepeccion: {},
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      usuario: JSON.parse(localStorage.getItem('userData')),
      columnsReporte: [],
      rowsReporte: [],
    }
  },
  computed: {
    tituloPrincipal() {
      return tituloPrincipal
    },
    tituloSecundario() {
      return tituloSecundario
    },
    subtitulo() {
      return subtitulo
    },
    tituloDescarga() {
      return tituloDescarga
    },
  },
  created() {
    this.rows = []
    const fechas = obtenerFechaInicioFin()
    this.fechaFin = fechas.fechaFin
    this.fechaInicio = fechas.fechaInicio
    this.loadReport()
  },
  methods: {
    async onRowClick(params) {
      this.insepeccion = await findInspeccionId(params.row.id)
      this.dialog = true
      this.$emit('update-numero-poste', this.insepeccion.numeroPoste)
    },
    async loadReport() {
      const fechaInicio = new Date(this.fechaInicio)
      const fechaFin = sumarDiasFecha(new Date(this.fechaFin), 1)
      const filter = {
        where: {
          and: [
            { fechaCreacion: { gte: fechaInicio.toISOString() } },
            { fechaCreacion: { lte: fechaFin.toISOString() } },
            { companyId: this.usuario.idCompany },
            { numeroPoste: this.numeroPoste },
          ],
        },
        order: ['fechaCreacion DESC'],
      }

      if (this.tipoInspeccion.value !== '') {
        filter.where.and.push({ tipo: this.tipoInspeccion.value })
      }

      if (this.etapaBusqueda.value !== '') {
        filter.where.and.push({ estado: this.etapaBusqueda.value })
      }

      const data = await findInspecciones(filter)
      this.rowsReporte = getRows([...data])
      // eslint-disable-next-line no-restricted-syntax
      for await (const item of data) {
        item.fechaAtencion = item.imagenes.length > 0 ? formatDate(item.imagenes[0].fechaHora, '/') : ''
        item.fechaCreacion = formatDate(item.fechaCreacion, '/')
        // item.ruta = await getItem(8, item.rutaId)
      }
      this.rows = data

      this.columnsReporte = getColumns()
    },
  },
}
</script>

<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
